import React, { Component } from "react";
import "./style.scss";
import Header from "../../header";
import Layout from "../../layout";
import ProjectLayout from "../../projectlayout2";
import sliderImages from "./slider.data";
import SEO from "../../seo";

const details = {
  title: "A.T.R.A Villa, Mashobra",
  address: "Mashobra, Shimla",
  client: "Atul Nanda, Advocate General of Punjab",
  area: "10,000 Sq. Ft.",
  project:
    "A.T.R.A Villa, Mashobra for Advocate General of Punjab, Mashobra, Shimla",
  category: "Residential | Architecture | House",
  status: "Completed",
  backlink: "/projects/residential/",
};

export default class Projectatravillamashobra extends Component {
  render() {
    return (
      <Layout>
        <SEO
          title="A&D Studio | Signature Residential Projects | A.T.R.A Villa, Mashobra"
          img="https://archdesignsstudio.com/assets/linkpreview.png"
          description="Explore the beautiful villa located in Shimla that was designed by our team for Atul Nanda, the Advocate General of Punjab."
        />
        <Header isWhite />
        <ProjectLayout details={details} projectimages={sliderImages} />
      </Layout>
    );
  }
}
